.NewAnswers {
}
.listCards {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin: 0px auto;
  max-width: 1440px;
}
@media (max-width: 767px) {
  .listCards {
    flex-direction: column;
  }
}

.card {
  width: calc(25% - 10px * 2);
  min-height: 200px;
  margin: 10px;
  background: #ffffff;
}
@media (max-width: 1024px) {
  .card {
    width: calc(50% - 10px * 2);
  }
}
@media (max-width: 768px) {
  .card {
    width: 100%;
    max-width: 480px;
    margin-left: auto;
    margin-right: auto;
  }
}
.card.rounded {
  border-radius: 4px;
}
.card.shadowed {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 0 2px 0 rgba(0, 0, 0, 0.1);
  transition: box-shadow ease-out 0.2s;
}
.card.shadowed:hover,
.card.shadowed:active {
  box-shadow: 0 19px 38px 0 rgba(0, 0, 0, 0.3), 0 15px 12px 0 rgba(0, 0, 0, 0.2);
}

.cardContent {
  padding: 20px;
  width: auto;
}
