:root {
  --gold-web-golden: hsl(50, 95%, 56%);
  --spanish-orange: hsl(24, 100%, 45%);
  --persian-green: hsl(175, 80%, 33%);
  --granite-gray: hsl(0, 0%, 40%);
  --spring-green: hsl(143, 85%, 58%);
  --ultramarine: hsl(260, 100%, 44%);
  --blue-violet: hsl(272, 76%, 53%);
  --smoky-black: hsl(0, 0%, 7%);
  --dark-orange: hsl(33, 100%, 50%);
  --winter-sky: hsl(332, 100%, 51%);
  --cultured-1: hsl(0, 0%, 98%);
  --cultured-2: hsl(0, 0%, 95%);
  --cultured-3: hsl(0, 0%, 94%);
  --light-gray: hsl(0, 0%, 80%);
  --alice-blue: hsl(206, 89%, 93%);
  --white_50: hsla(0, 0%, 100%, 0.5);
  --white-1: hsl(0, 0%, 100%);
  --white-2: hsl(0, 0%, 99%);
  --black: hsl(0, 0%, 0%);

  --ff-spartan: "League Spartan", sans-serif;

  --fs-1: 3rem;
  --fs-2: 2.7rem;
  --fs-3: 2.2rem;
  --fs-4: 1.8rem;
  --fs-5: 1.6rem;

  --fw-400: 400;
  --fw-500: 500;
  --fw-600: 600;
  --fw-700: 700;
  --fw-900: 900;

  --section-padding: 60px;

  --shadow-1: 0 2px 25px hsla(0, 0%, 0%, 0.1);
  --shadow-2: 0 8px 14px hsla(0, 0%, 0%, 0.1);
  --shadow-3: 0 14px 30px hsla(0, 0%, 0%, 0.05);

  --radius-6: 6px;
  --radius-12: 12px;

  --gradient-1: linear-gradient(
    to right,
    var(--winter-sky),
    var(--dark-orange)
  );
  --gradient-2: linear-gradient(
    to right,
    var(--ultramarine),
    var(--blue-violet)
  );
  --gradient-3: linear-gradient(
    to right,
    var(--winter-sky),
    var(--dark-orange)
  );
  --gradient-4: linear-gradient(
    to right,
    var(--ultramarine),
    var(--blue-violet)
  );
  --gradient-5: linear-gradient(
    to right,
    var(--persian-green),
    var(--spring-green)
  );
  --gradient-6: linear-gradient(
    to right,
    var(--spanish-orange),
    var(--gold-web-golden)
  );

  /**
   * transition
   */

  --transition-1: 0.25s ease;
  --transition-2: 0.5s ease;
  --cubic-out: cubic-bezier(0.33, 0.85, 0.4, 0.96);
}

/*-----------------------------------*\
  #RESET
\*-----------------------------------*/

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

li {
  list-style: none;
}

a {
  text-decoration: none;
  color: inherit;
}

a,
img,
svg,
span,
input,
button,
strong,
ion-icon {
  display: block;
}

img {
  height: auto;
}

input,
button {
  background: none;
  border: none;
  font: inherit;
}

input {
  width: 100%;
}

button {
  cursor: pointer;
}

ion-icon {
  pointer-events: none;
}

html {
  font-family: var(--ff-spartan);
  font-size: 10px;
  scroll-behavior: smooth;
}

body {
  background-color: var(--white-1);
  color: var(--granite-gray);
  font-size: 1.6rem;
}

:focus-visible {
  outline-offset: 4px;
}

/*-----------------------------------*\
  #REUSED STYLE
\*-----------------------------------*/

.container {
  padding-inline: 15px;
}

.section {
  padding-block: var(--section-padding);
}

.w-100 {
  width: 100%;
}

.h1,
.h2,
.h3 {
  color: var(--black);
  font-weight: var(--fw-900);
  line-height: 1.3;
}

.h1 {
  font-size: var(--fs-1);
}

.h2 {
  font-size: var(--fs-2);
}

.h3 {
  font-size: var(--fs-3);
  font-weight: var(--fw-600);
}

.section-text {
  line-height: 1.7;
}

.btn {
  min-height: 50px;
  color: var(--white-1);
  padding-inline: 25px;
  border-radius: var(--radius-6);
}

.btn-primary {
  background-image: var(--gradient-1);
}

.btn-secondary {
  background-image: var(--gradient-2);
}

.btn-link {
  display: flex;
  align-items: center;
  gap: 5px;
  transition: var(--transition-1);
}

.btn-link:is(:hover, :focus) {
  letter-spacing: 0.3px;
}

/*-----------------------------------*\
  #HEADER
\*-----------------------------------*/

.header .btn {
  display: none;
}

.header {
  background-color: var(--white-1);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding-block: 20px;
  z-index: 4;
}

.header.active {
  position: fixed;
  top: -75px;
  box-shadow: var(--shadow-1);
  animation: slide-in 0.5s var(--cubic-out) forwards;
}

@keyframes slide-in {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(75px);
  }
}

.header > .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

.logo {
  color: var(--smoky-black);
  font-size: 3.5rem;
  font-weight: var(--fw-700);
  line-height: 1;
}

.nav-toggle-btn {
  font-size: 30px;
}

.nav-toggle-btn.active .menu-icon,
.nav-toggle-btn .close-icon {
  display: none;
}

.nav-toggle-btn .menu-icon,
.nav-toggle-btn.active .close-icon {
  display: block;
}

.navbar {
  position: absolute;
  top: 100%;
  left: 50%;
  width: calc(100% - 30px);
  transform: translateX(-50%);
  background-color: var(--white-1);
  box-shadow: var(--shadow-2);
  /* overflow: hidden; */

  visibility: hidden;
  max-height: 0;
  transition: 0.25s var(--cubic-out);
}

.navbar.active {
  visibility: visible;
  max-height: 300px;
  transition-duration: 0.35s;
}

.navbar-item:not(:last-child) {
  border-block-end: 1px solid var(--alice-blue);
}

.navbar-link {
  color: var(--black);
  font-size: var(--fs-4);
  font-weight: var(--fw-500);
  padding: 16px;
  transition: var(--transition-1);
}

.navbar-link:is(:hover, :focus) {
  color: var(--winter-sky);
}

/*-----------------------------------*\
  #HERO
\*-----------------------------------*/

.hero {
  padding-block-start: calc(73px + var(--section-padding));
  /* background-color: var(--cultured-1); */
}

.hero-banner {
  background-color: var(--light-gray);
  aspect-ratio: 1 / 0.8;
}

.hero-title {
  margin-block: 30px 15px;
}

.hero-form {
  margin-block: 20px 30px;
}

.input-field {
  background-color: var(--cultured-3);
  padding: 20px 15px;
  border-radius: var(--radius-6);
  margin-block-end: 15px;
}

.input-field::placeholder {
  transition: var(--transition-1);
}

.input-field:focus::placeholder {
  opacity: 0;
}

.hero-list {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 20px;
}

.hero-item {
  display: flex;
  align-items: center;
  gap: 5px;
}

/*-----------------------------------*\
  #SERVICE
\*-----------------------------------*/

.service {
  background-color: var(--white-1);
}

.service :is(.section-title, .section-text) {
  text-align: center;
}

.service .section-text {
  margin-block: 10px 35px;
}

.service-list {
  display: grid;
  gap: 25px;
}

.service-card {
  border-radius: var(--radius-6);
  overflow: hidden;
  box-shadow: var(--shadow-1);
}

.service-card .card-banner {
  background-color: var(--light-gray);
  aspect-ratio: 1 / 0.47;
}

.service-card .card-content {
  padding: 20px;
}

.service-card .card-title {
  transition: var(--transition-1);
}

.service-card .card-title:is(:hover, :focus) {
  color: var(--winter-sky);
}

.service-card .card-text {
  margin-block: 10px 20px;
  line-height: 1.8;
}

.service-card .btn-link {
  color: var(--ultramarine);
}

/*-----------------------------------*\
  #FEATURES
\*-----------------------------------*/

.features {
  background-color: var(--white-2);
}

.features .section-text {
  margin-block: 10px 15px;
}

.features-content {
  margin-block-end: 25px;
}

.features-item {
  display: flex;
  align-items: center;
  gap: 10px;
  background-color: var(--white-1);
  padding: 15px;
  box-shadow: var(--shadow-3);
  border-radius: var(--radius-6);
}

.features-item:not(:last-child) {
  margin-block-end: 15px;
}

.features-item .item-title {
  color: var(--black);
  font-size: var(--fs-5);
  font-weight: var(--fw-600);
}

.features-banner {
  max-width: max-content;
  background-color: var(--light-gray);
  border-radius: var(--radius-12);
  overflow: hidden;
}

.features-banner.one {
  aspect-ratio: 1 / 0.83;
  margin-block-end: 25px;
}

.features-banner.two {
  margin-inline: auto;
  aspect-ratio: 1 / 0.71;
}

/*-----------------------------------*\
  #ABOUT
\*-----------------------------------*/

.about {
  text-align: center;
}

.about .section-text:first-of-type {
  margin-block: 5px 30px;
}

.about-list {
  display: grid;
  gap: 25px;
}

.about-card {
  color: var(--white-1);
  padding: 20px;
  border-radius: var(--radius-12);
}

.about-card-1 {
  background-image: var(--gradient-3);
}

.about-card-2 {
  background-image: var(--gradient-4);
}

.about-card-3 {
  background-image: var(--gradient-5);
}

.about-card-4 {
  background-image: var(--gradient-6);
}

.about-card .card-banner {
  min-width: 150px;
  height: 150px;
  aspect-ratio: 1 / 1;
  background-color: var(--white_50);
  border-radius: 50%;
  display: grid;
  place-items: center;
  margin-inline: auto;
  transition: var(--transition-2);
}

.about-card:is(:hover, :focus-within) .card-banner {
  background-color: var(--white-1);
}

.about-card .card-title {
  color: var(--white-1);
  margin-block: 20px 10px;
}

.about-card .card-text {
  line-height: 1.8;
  margin-block-end: 15px;
}

.about .btn-link {
  justify-content: center;
}

.about .section-text:last-of-type {
  max-width: 50ch;
  margin-inline: auto;
  margin-block-start: 25px;
}

.about .section-text:last-of-type .btn-link {
  color: var(--blue-violet);
  display: inline-flex;
}

.about .section-text:last-of-type .btn-link:is(:hover, :focus) {
  color: var(--winter-sky);
  letter-spacing: 0;
}

/*-----------------------------------*\
  #STATS
\*-----------------------------------*/

.stats {
  background-color: var(--cultured-2);
}

.stats-banner {
  max-width: max-content;
  margin-inline: auto;
  aspect-ratio: 1 / 0.78;
  margin-block-end: 25px;
}

.stats-list {
  display: grid;
  gap: 25px;
}

.stats-item {
  position: relative;
  padding-block: 5px;
  padding-inline-start: 15px;
}

.stats-item::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 3px;
  height: 100%;
  border-radius: 5px;
}

.stats-item.purple::before {
  background-color: var(--ultramarine);
}

.stats-item.red::before {
  background-color: var(--winter-sky);
}

.stats-item.green::before {
  background-color: var(--spring-green);
}

.stats-item.yellow::before {
  background-color: var(--dark-orange);
}

.stats-item .item-title {
  color: var(--black);
  font-size: 3.5rem;
  font-weight: var(--fw-900);
  margin-block-end: 5px;
}

.stats-item .item-title .span {
  color: var(--granite-gray);
  font-weight: var(--fw-400);
  display: inline-block;
  font-size: var(--fs-4);
}

/*-----------------------------------*\
  #APP
\*-----------------------------------*/

.app-content {
  margin-block-end: 25px;
}

.app .section-text {
  margin-block: 5px 10px;
}

.app .btn-group {
  display: flex;
  gap: 15px;
}

.app .btn {
  min-height: auto;
  padding: 15px;
}

.app .btn img {
  margin-block-end: 10px;
}

.app .btn .strong {
  margin-block-start: 5px;
  font-weight: var(--fw-600);
}

.app-banner {
  max-width: max-content;
  /* margin-inline: auto; */
  aspect-ratio: 1 / 0.72;
}

/*-----------------------------------*\
  #SUPPORT
\*-----------------------------------*/

.support {
  background-image: var(--gradient-2);
  color: var(--white-1);
}

.support .section-title {
  color: var(--white-1);
}

.support .section-text {
  margin-block: 10px 15px;
}

.support .btn {
  max-width: max-content;
  display: grid;
  place-items: center;
}

/*-----------------------------------*\
  #FOOTER
\*-----------------------------------*/

.footer-top {
  background-color: var(--cultured-1);
}

.footer-top .container {
  display: grid;
  gap: 25px;
}

.footer .logo {
  margin-block-end: 25px;
}

.footer-img {
  max-width: max-content;
}

.footer-list-title {
  color: var(--black);
  font-size: var(--fs-3);
  font-weight: var(--fw-600);
  margin-block-end: 20px;
}

.footer-list > li:not(:last-child) {
  margin-block-end: 15px;
}

.footer-link {
  position: relative;
  max-width: max-content;
  transition: var(--transition-1);
}

.footer-link::after {
  content: "";
  position: absolute;
  top: 6px;
  left: calc(100% + 10px);
  background-color: var(--ultramarine);
  width: 20px;
  height: 2px;
  transition: var(--transition-1);
  opacity: 0;
}

.footer-link:is(:hover, :focus) {
  color: var(--ultramarine);
}

.footer-link:is(:hover, :focus)::after {
  opacity: 1;
}

.footer-item {
  display: flex;
  align-items: baseline;
  gap: 5px;
}

.footer-item .span {
  color: var(--black);
}

.footer-item-link {
  display: inline-block;
  color: var(--granite-gray);
  transition: var(--transition-1);
}

.footer-item-link:is(:hover, :focus) {
  color: var(--ultramarine);
}

.footer-bottom {
  padding-block: 25px;
  text-align: center;
  line-height: 1.3;
}

.copyright {
  margin-block-end: 15px;
}

.copyright .span {
  display: inline-block;
  color: var(--winter-sky);
  font-weight: var(--fw-500);
}

.copyright-link {
  display: inline-block;
  font-weight: var(--fw-600);
  background-image: var(--gradient-3);
  -webkit-text-fill-color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
}

.footer-bottom-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 5px 20px;
}

.footer-bottom-link {
  transition: var(--transition-1);
}

.footer-bottom-link:is(:hover, :focus) {
  color: var(--ultramarine);
}

/*-----------------------------------*\
  #BACK TO TOP
\*-----------------------------------*/

.back-top-btn {
  background-color: white;
  color: black;
  position: fixed;
  bottom: 10px;
  right: 20px;
  border: 1px solid var(--light-gray);
  font-size: 2.2rem;
  padding: 12px;
  border-radius: 50%;
  opacity: 0;
  transition: var(--transition-1);
  z-index: 4;
}

.back-top-btn.active {
  transform: translateY(-10px);
}

/*-----------------------------------*\
  #MEDIA QUERIES
\*-----------------------------------*/

/**
 * responsive for large than 480px screen
 */

@media (min-width: 250px) and (max-width: 700px) {
  /**
   * REUSED STYLE
   */

  .app-banner {
    content-visibility: hidden;
  }
  .move {
    text-align: center;
  }
  body {
    background-color: var(--white-1);
    color: var(--granite-gray);
    font-size: 2rem;
  }

  .app .section-text {
    margin-block: 5px 10px;
    text-align: center;
  }

  .header .btn {
    display: block;
    margin-inline-start: auto;
    min-height: 40px;
    padding-inline: 15px;
  }
}

/**
 * responsive for large than 575px screen
 */

@media (min-width: 575px) {
  /**
   * REUSED STYLE
   */

  .container {
    max-width: 540px;
    margin-inline: auto;
  }

  /**
   * HERO
   */

  .hero-form {
    position: relative;
  }

  .hero-form .btn {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
  }

  /**
   * SERVICE, ABOUT, STATS, FOOTER
   */

  .service-list,
  .about-list,
  .stats-list,
  .footer-top .container {
    grid-template-columns: 1fr 1fr;
  }

  /**
   * ABOUT
   */

  .about-card {
    min-height: 100%;
  }

  /**
   * APP
   */

  .app .btn {
    display: flex;
    align-items: center;
    gap: 15px;
    padding-inline: 25px;
  }

  .app .btn img {
    margin-block-end: 0;
  }
}

/**
 * responsive for large than 768px screen
 */

@media (min-width: 768px) {
  /**
   * RESET
   */

  body {
    font-size: 2rem;
  }

  /**
   * CUSTOM PROPERTY
   */

  :root {
    /**
     * typography
     */

    --fs-1: 5rem;
    --fs-2: 3.5rem;
    --fs-3: 2.5rem;
  }

  /**
   * REUSED STYLE
   */

  .container {
    max-width: 720px;
  }

  /**
   * HEADER
   */

  .header .btn {
    font-size: 1.7rem;
    font-weight: var(--fw-500);
    min-height: 45px;
    padding-inline: 25px;
  }

  /**
   * SERVICE
   */

  .service-card {
    --radius-6: 16px;
  }

  .service-card .card-content {
    padding: 30px;
  }

  /**
   * FEATURES
   */

  .features-item .item-title {
    --fs-5: 2rem;
  }

  .features-banner.two {
    margin-inline: auto 0;
    margin-block-start: -180px;
  }

  /**
   * STATS
   */

  .stats-list {
    padding-inline: 60px;
  }

  .stats-item {
    padding-inline-start: 20px;
  }

  .stats-item .item-title {
    font-size: 4rem;
  }

  /**
   * APP
   */

  .app .btn {
    padding: 20px 30px;
  }

  .app-content .span {
    font-size: 1.5rem;
  }

  .app-content .strong {
    font-size: 2rem;
  }
}

/**
 * responsive for large than 992px screen
 */

@media (min-width: 992px) {
  /**
   * CUSTOM PROPERTY
   */

  :root {
    /**
     * typography
     */

    --fs-2: 4rem;
    --fs-3: 2.9rem;

    /**
     * spacing
     */

    --section-padding: 100px;
  }

  /**
   * REUSED STYLE
   */

  .container {
    max-width: 960px;
  }

  :is(.service, .about, .app) .section-text {
    max-width: 55ch;
    margin-inline: auto;
  }

  /**
   * HEADER
   */

  .nav-toggle-btn {
    display: none;
  }

  .navbar,
  .navbar.active {
    all: unset;
  }

  .navbar-list {
    display: flex;
    gap: 5px;
  }

  .navbar-item:not(:last-child) {
    border-block-end: none;
  }

  .navbar-link {
    --fs-4: 2rem;
  }

  .header .btn {
    margin-inline-start: 0;
    min-height: 50px;
    padding-inline: 40px;
  }

  /**
   * HERO
   */

  .hero .container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    gap: 50px;
  }

  .hero-title {
    margin-block-start: 0;
  }

  /**
   * SERVICE
   */

  .service-list {
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
  }

  /**
   * FEATURES
   */

  .features .container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    gap: 25px;
  }

  .features-content {
    margin-block-end: 0;
  }

  .features-item {
    padding: 20px;
  }

  .features-item:not(:last-child) {
    margin-block-end: 20px;
  }

  .features-item .item-title {
    --fs-5: 2.4rem;
  }

  /**
   * ABOUT
   */

  .about-list {
    gap: 30px;
  }

  .about-card {
    padding: 40px;
  }

  /**
   * STATS
   */

  .stats .container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: flex-start;
  }

  .stats-banner {
    margin-block-end: 0;
  }

  .stats-list {
    grid-template-columns: 1fr;
  }

  .stats-item:nth-child(even) {
    margin-inline-start: auto;
  }

  .stats-item .item-title {
    font-size: 4.5rem;
  }

  .stats-text {
    font-size: 2.2rem;
  }

  /**
   * APP
   */

  .app :is(.section-title, .section-text) {
    text-align: center;
  }

  .app .btn-group {
    justify-content: center;
  }

  /**
   * SUPPORT
   */

  .support .container {
    display: grid;
    grid-template-columns: 1fr 0.5fr;
    justify-items: self-end;
    align-items: center;
  }

  /**
   * FOOTER
   */

  .footer .container {
    grid-template-columns: 1fr 0.8fr 0.8fr 1fr;
  }

  .footer-list-title {
    margin-block-end: 30px;
  }

  .footer-list > li:not(:last-child) {
    margin-block-end: 25px;
  }

  .footer-bottom {
    padding-block: 30px;
  }

  .footer-bottom .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .copyright {
    margin-block-end: 0;
  }
}

/**
 * responsive for large than 1200px screen
 */

@media (min-width: 1200px) {
  /**
   * CUSTOM PROPERTY
   */

  :root {
    /**
     * typography
     */

    --fs-1: 6rem;
    --fs-2: 5rem;
  }

  /**
   * REUSED STYLE
   */

  .container {
    max-width: 1140px;
  }

  /**
   * FEATURES
   */

  .features .container {
    grid-template-columns: 1fr max-content;
    gap: 70px;
  }

  /**
   * ABOUT
   */

  .about-card {
    display: flex;
    align-items: center;
    gap: 30px;
    text-align: left;
  }

  .about-card .card-title {
    margin-block-start: 0;
  }

  .about .btn-link {
    justify-content: flex-start;
  }

  /**
   * APP
   */

  .app .container {
    display: grid;
    grid-template-columns: 0.7fr 1fr;
    align-items: center;
    gap: 25px;
  }

  .app-content {
    margin-block-end: 0;
  }

  .app :is(.section-title, .section-text) {
    text-align: left;
  }

  .app .section-title {
    --fs-2: 4rem;
  }

  .app .btn-group {
    justify-content: flex-start;
  }
}
/* for IDE */

.window {
  width: 100%;
  background-color: #fff;
  /* box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); */
  margin: 0 0 50px 10%;
  border-radius: 5px;
  overflow: hidden;
}

.headclass {
  background-color: #222;
  padding: 10px;
  border-bottom: 1px solid #444;
}

.btn {
  background: none;
  border: none;
  color: #fff;
  font-size: 20px;
  cursor: pointer;
  margin-left: 5px;
}

.close-btn:hover,
.minimize-btn:hover,
.maximize-btn:hover {
  color: #ff5f56;
}

.editor {
  width: 100%;
  height: auto;
  outline: none;
  border: none;
  background-color: #1e1e1e;
  padding: 10px;
  overflow: auto;
}

.editor pre {
  width: 100%;
  background-color: #1e1e1e;
  padding: 6px;
  border-radius: 5px;
  overflow: auto;
}

.editor code {
  color: #cfcfcf;
  font-family: "Consolas", "Courier New", monospace;
  font-size: 16px;
  line-height: 1.5;
}
.color-1 {
  color: #d2756c;
} /* RED */
.color-2 {
  color: #ac886b;
} /* BROWN */
.color-3 {
  color: #7ca0ba;
} /* BLUE */
.color-4 {
  color: #d8d18d;
} /* YELLOW */
.color-5 {
  color: #86b38a;
} /* GREEN */
.color-6 {
  color: #aa8297;
} /* PURPLE */
.color-7 {
  color: #d7cd74;
} /* DARK YELLOW */

.cm-dialog .headclass {
  padding: 10px;
}
.cm-dialog {
  width: 600px;
  height: 300px;
  box-shadow: 2px 2px 10px 10px rgba(0, 0, 0, 0.16);
  border-radius: 5px;
  --background: #151718;
  background: var(--background, white);
  overflow: hidden;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.cm-icons {
  list-style: none;
  margin: 0;
  padding: 0;
}
.cm-icons li {
  display: inline-block;
  width: 12px;
  height: 12px;
  background: red;
  border-radius: 50%;
}
.cm-icons li:first-child {
  background: #ff5f56;
}
.cm-icons li:nth-child(2) {
  background: #ffbd2e;
}
.cm-icons li:last-child {
  background: #27c93f;
}
@media screen and (max-width: 990px) {
  .window {
    width: 70rem;
  }
  .navbar a {
    color: #000000 !important;
  }
}
.phone {
  display: none;
}
/* Waving Icon */
.wave {
  animation-name: wave-animation; /* Refers to the name of your @keyframes element below */
  animation-duration: 2.5s; /* Change to speed up or slow down */
  animation-iteration-count: infinite; /* Never stop waving :) */
  transform-origin: 70% 70%; /* Pivot around the bottom-left palm */
  display: inline-block;
}

@keyframes wave-animation {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(14deg);
  } /* The following five values can be played with to make the waving more or less extreme */
  20% {
    transform: rotate(-8deg);
  }
  30% {
    transform: rotate(14deg);
  }
  40% {
    transform: rotate(-4deg);
  }
  50% {
    transform: rotate(10deg);
  }
  60% {
    transform: rotate(0deg);
  } /* Reset for the last half to pause */
  100% {
    transform: rotate(0deg);
  }
}

/* Animated typing text */

.move {
  border-right: 0.06em solid #fff;
  font-size: 0.63em;
  width: 25ch;
  margin: 0.5ch;
  padding: 0.1ch;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  animation: digitacao 1.7s steps(28, end),
    barra 0.1s step-end infinite alternate;
}
.hero-text {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
}

@keyframes digitacao {
  0% {
    width: 0;
  }
}
@keyframes barra {
  50% {
    border-color: transparent;
  }
}

@media (max-width: 1200px) {
  .move {
    justify-content: center;
    align-items: center;
    margin: auto;
  }
  .window {
    /* padding-right: 250px; */
  }
}
@media (max-width: 768px) {
  .editor {
    display: none !important;
  }
  .headclass {
    display: none !important;
  }
}
@media (max-width: 992px) {
  .window {
    width: 120%;
  }
  .ss .section-text {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
@media (max-width: 670px) {
  .window {
    width: 720px;
    overflow: hidden;
  }
  .phone {
    display: block !important;
  }

  .ss .section-text {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

/* Contact Form */

.form input {
  width: 100%;
  padding: 10px;
  border: none;
  border-bottom: 2px solid #777777;
  margin-bottom: 20px;
  font-size: 16px;
  outline: none;
}
.form .btn {
  border: none;
  cursor: pointer;
  background-color: #32cd9c;
  margin: 15px 0;
  font-size: 16px;
  width: 100%;
  padding: 14px;
}
.form .btn:hover {
  background-color: #1f7d5f;
  color: white;
}
/* Night / Light Mode */
.toggle-switch {
  position: relative;
  width: 100px;
  height: 50px;
  --light: #d8dbe0;
  --dark: #28292c;
  --link: rgb(27, 129, 112);
  --link-hover: rgb(24, 94, 82);
}

.switch-label {
  position: absolute;
  width: 100%;
  height: 50px;
  background-color: var(--dark);
  border-radius: 25px;
  cursor: pointer;
  border: 3px solid var(--dark);
}

.checkbox {
  position: absolute;
  display: none;
}

.slider {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 25px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.checkbox:checked ~ .slider {
  background-color: var(--light);
}

.slider::before {
  content: "";
  position: absolute;
  top: 10px;
  left: 10px;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  -webkit-box-shadow: inset 12px -4px 0px 0px var(--light);
  box-shadow: inset 12px -4px 0px 0px var(--light);
  background-color: var(--dark);
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.checkbox:checked ~ .slider::before {
  -webkit-transform: translateX(50px);
  -ms-transform: translateX(50px);
  transform: translateX(50px);
  background-color: var(--dark);
  -webkit-box-shadow: none;
  box-shadow: none;
}
/* For popup Message */
.popup-bg {
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 800px;
  height: 100vh;
  z-index: 100;
  padding: 10%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.popup-msg {
  width: 80%;
  min-width: 300px;
  margin: auto;
  position: relative;
  z-index: 200;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 1em;
  text-align: center;
  transition: all 0.5s;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
}
@media (min-width: 768px) {
  .popup-msg {
    width: 70%;
    min-width: 350px;
    padding: 1.5em;
  }
}

@media (min-width: 992px) {
  .popup-msg {
    width: 60%;
    min-width: 400px;
    padding: 2em;
  }
}

@media (min-width: 1200px) {
  .popup-msg {
    width: 50%;
  }
}

.close-x {
  position: absolute;
  top: 12px;
  right: 12px;
  font-size: 12px;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.1);
  padding: 0.5em;
}

.popup-msg h2 {
  color: #58a2d3;
  margin: 1em 0 0.3em;
}

a.give-button {
  padding: 12px 1em;
  background-color: #58a2d3;
  color: #fff;
  border-radius: 100px;
  margin: 1em auto;
  display: block;
  max-width: 200px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 1.6em;
}
.give-button {
  padding: 12px 1em;
  background-color: #58a2d3;
  color: #fff;
  border-radius: 100px;
  margin: 1em auto;
  display: block;
  max-width: 200px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 1.6em;
}

.popup-bg {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.fadeup {
  transform: translateY(0px);
}
.navbar-item {
  position: relative;
}

.dropdown-menu {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  width: 150px;
  height: 90px;
  text-align: center;
  justify-content: center;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  list-style: none;
  padding: 0;
  z-index: 100;
}
.dropdown-menu li {
  color: black !important;
  padding: 10px !important;
}
/* Dark Mode */
.dark-mode #color-changer,
.dark-mode .move,
.dark-mode .section-text,
.dark-mode .card-title,
.dark-mode .section-title {
  color: white;
}

.dark-mode li span.navbar-link {
  color: white !important;
}

.dark-mode ul .dropdownList {
  background-color: white !important;
}

.dark-mode li a.navbar-link {
  color: white !important;
}
.dark-mode .cFNfWl svg {
  color: white !important;
}

.dark-mode {
  background-color: #292c34;
}
.dark-mode h1 a {
  color: white;
}
.dark-mode main {
  background-color: #292c34;
}
.dark-mode .dropdown-menu a {
  color: #292c34;
}
.dark-mode .service {
  background-color: #292c34;
}
.dark-mode header {
  background-color: #292c34;
}
.dark-mode header .navbar-link {
  color: white !important;
}
.dark-mode .card-text {
  color: white;
}
.dark-mode .dropdown-menu {
  background-color: white;
}
.dark-mode a.btn-link {
  color: #d7caf0;
}
.dropdown-visible {
  display: block;
}

/* Dashboard */
.containeer {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .containeer {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .containeer {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .containeer {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .containeer {
    max-width: 1140px;
  }
}
@media (min-width: 1400px) {
  .containeer {
    max-width: 1320px;
  }
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}
.col-6 {
  position: relative;
  width: 50%;
  padding-right: 15px;
  padding-left: 15px;
}

@media (max-width: 576px) {
  .col-6 {
    width: 100%;
  }
}
.ccards {
  --pixel: 300px;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.cards {
  width: var(--pixel);
  height: var(--pixel);
  margin: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  cursor: pointer;
  transition: all 0.4s;
  text-align: center;
}
.cards:hover,
.cards:active {
  color: white;
}
.cards::before,
.cards::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(243, 243, 243);
  z-index: -1;
  border-radius: 10px;
  transition: all 0.4s;
}
.cards::after {
  background: #f8485e;
  transform-origin: right bottom;
  transform: translate(10%, 10%) scale(0.3);
  border-radius: 50%;
}
.cards:hover::after,
.cards:active::after {
  transform: translate(0) scale(1);
  border-radius: 10px;
  border-bottom-right-radius: 40px;
  box-shadow: rgba(240, 46, 170, 0.4) 5px 5px, rgba(240, 46, 170, 0.3) 10px 10px,
    rgba(240, 46, 170, 0.2) 15px 15px, rgba(240, 46, 170, 0.1) 20px 20px,
    rgba(240, 46, 170, 0.05) 25px 25px;
}
.cards:hover::before,
.cards:active::before {
  transform-origin: right bottom;
  transform: translate(10%, 10%) scale(0.3);
  border-radius: 50%;
  z-index: 1;
}

/* All users */

table {
  width: 80%;
  margin: 0 auto;
  border-collapse: collapse;
  overflow: hidden;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}
th,
td {
  padding: 15px;
  background-color: rgba(255, 255, 255, 0.2);
  color: #fff;
}
th {
  text-align: left;
}
thead th {
  background-color: #878f55;
}
tbody tr:hover {
  background-color: rgba(255, 255, 255, 0.3);
}
tbody td {
  position: relative;
}
tbody td:hover:before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: -9999px;
  bottom: -9999px;
  background-color: rgba(255, 255, 255, 0.2);
  z-index: -1;
}

th,
td,
tr {
  border: 1px solid #000 !important;
  padding: 8px;
  color: #292c34;
  text-align: center;
}
.dark-mode table th,
.dark-mode table td,
.dark-mode table tr {
  border: 1px solid #ffffff !important;
}
.dark-mode table th,
.dark-mode table td,
.dark-mode .table h1,
.dark-mode table tr {
  color: white !important;
}
.table {
  padding-top: 200px;
}
.table h1 {
  justify-content: center;
  text-align: center;
  padding-bottom: 50px;
}

.approved {
  color: green;
}

.pending {
  color: rgb(213, 107, 107);
}
.approveBtn {
  --bezier: cubic-bezier(0.22, 0.61, 0.36, 1);
  --edge-light: hsla(0, 0%, 50%, 0.8);
  --text-light: rgba(255, 255, 255, 0.4);
  --back-color: 110, 50%;

  color: white;
  cursor: pointer;
  padding: 0.7em 1em;
  border-radius: 0.5em;
  min-height: 2.4em;
  min-width: 3em;
  display: flex;
  align-items: center;
  gap: 0.5em;

  font-size: 18px;
  letter-spacing: 0.05em;
  line-height: 1;
  font-weight: bold;

  background: linear-gradient(
    140deg,
    hsla(var(--back-color), 50%, 1) min(2em, 20%),
    hsla(var(--back-color), 50%, 0.6) min(8em, 100%)
  );
}

.approveBtn:hover {
  --edge-light: hsla(0, 0%, 50%, 1);
  text-shadow: 0px 0px 10px var(--text-light);
  box-shadow: inset 0.4px 1px 4px var(--edge-light),
    2px 4px 8px hsla(0, 0%, 0%, 0.295);
  transform: scale(1.1);
}

/* dashboard nav */

.menu-trigger img {
  position: absolute;
  top: 20px;
  height: 60px;
  width: 60px;
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
  background-color: #fff;
}

.dropdown-menus {
  position: absolute;
  top: 100px;
  left: 90px;
  background-color: #fff;
  border-radius: 10%;
  padding: 10px 20px;
  width: 200px;
}

.dropdown-menus::before {
  content: "";
  position: absolute;
  top: -5px;
  left: 90px;
  height: 20px;
  width: 20px;
  transform: rotate(45deg);
}

.dropdown-menus.active {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
  transition: 500ms ease;
  display: block;
}

.dropdown-menus.inactive {
  opacity: 0;
  visibility: hidden;
  transform: translateY(-20px);
  transition: 500ms ease;
}

h3 {
  width: 100%;
  text-align: center;
  font-size: 18px;
  padding: 20px 0;
  font-weight: 500;
  font-size: 18px;
  line-height: 1.2rem;
}

h3 span {
  font-size: 14px;
  font-weight: 400;
}

.dropdown-menus ul li {
  padding: 10px 0;
  border-top: 1px solid rgba(0, 0, 0, 0.05);
}

.dropdown-menus ul li:hover a {
  color: rgb(212, 33, 9);
  cursor: pointer;
}

.dropdown-menus ul li:hover img {
  opacity: 1;
  cursor: pointer;
}

.dropdownItems {
  display: flex;
  margin: 10px auto;
  align-items: center;
  background-color: #fff;
}

.dropdownItems img {
  max-width: 20px;
  margin-right: 10px;
  opacity: 0.5;
  transition: 500ms;
}

.dropdownItems a {
  max-width: 100px;
  margin-left: 10px;
  transition: 500ms;
}

/* question List */

.cf {
  display: table;
  clear: both;
}

.fl {
  float: left;
}

.fr {
  float: right;
}

.w-70 {
  width: 70%;
}

.w-30 {
  width: 30%;
}

.mla,
.mla-m,
.mla-l,
.mla-ns {
  margin-left: auto !important;
}

.mra,
.mra-m,
.mra-l,
.mra-ns {
  margin-right: auto !important;
}

.valign-child {
  display: flex;
  align-items: center;
}

.card {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 2px;
  box-shadow: 0 2px 5px #eee;
  display: block;
  height: auto;
  max-height: auto;
  overflow: hidden;
  margin-bottom: 10px;
  padding: 10px 15px;
  transition: max-height 0.3s ease;
}
.card h1,
.card p {
  margin: 0;
}
.card p {
  color: #888;
}

#qa {
  width: 100%;
  display: flex;
  max-width: 48rem;
  margin: 0 auto;
}
.mark {
  display: inline-block;
  padding: 0px 3px;
  background-color: #ff7;
  border-radius: 4px;
}
.question-content {
  max-height: 150px;
  max-width: 550px;
  width: 450px;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: max-height 0.7s ease;
}
.question-card {
  margin-bottom: 90px;
}
.question-card:hover .question-content {
  max-height: 1000px;
  height: auto;
  overflow: visible;
  text-overflow: clip;
}
#feed {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 10px auto;
}
#feed .question-card.card .question-title {
  margin-bottom: 10px;
}
#feed .question-card.card .question-title .permalink {
  color: gold;
  text-transform: uppercase;
  font-weight: 600;
}

#sidebar-qa-links {
  position: fixed;
}
#sidebar-qa-links a {
  border-radius: 2.5px;
  color: #ddd;
  display: block;
  padding: 10px 25px;
  letter-spacing: 0.1rem;
  opacity: 1;
  text-decoration: none;
  text-transform: uppercase;
  transition: 0.35s all ease;
}
#sidebar-qa-links a:hover {
  background-color: #eee;
  color: orange;
  font-weight: bold;
  opacity: 1;
}
.toggle-menu-option {
  background: none;
  border: none;
  cursor: pointer;
}
.menuOption {
  display: none;
  position: absolute;
  background-color: #fff;
  border: 1px solid #ddd;
  box-shadow: 0 2px 5px #eee;
  z-index: 1;
}

.toggle-menu-option:hover + .menuOption,
.menuOption:hover {
  display: block;
}

.menuOption ul li {
  padding: 10px;
  cursor: pointer;
}
.menuOption ul li:hover {
  background-color: #eee;
}

/* full question page */

.full-question {
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  max-width: 300vh;
}
.full-question-card {
  margin-bottom: 90px;
  padding: 10px 15px;
  border: 1px solid #ddd;
  border-radius: 2px;
  box-shadow: 0 2px 5px #eee;
  display: block;
  height: auto;
}
.full-question-content {
  max-height: auto;
  width: 750px;
  text-overflow: ellipsis;
}

/* full question code editor */
.full-Question-editor {
  width: 700px;
  height: auto;
  outline: none;
  border: none;
  background-color: #1e1e1e;
  padding: 10px;
  overflow: auto;
  white-space: pre-wrap;
}
.code-Editor {
  max-width: 200px;
}
.full-Question-editor pre {
  background-color: #1e1e1e;
  padding: 6px;
  border-radius: 5px;
  overflow: auto;
}

.full-Question-editor code {
  color: #cfcfcf;
  font-family: "Consolas", "Courier New", monospace;
  font-size: 16px;
  line-height: 1.5;
}
.app-banner-question {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
}

/* Answers.jsx */
.p-5 {
  padding: 5px;
}
.Question-card {
  border-radius: var(--radius-6);
  overflow: hidden;
  box-shadow: var(--shadow-1);
}

.Question-card .card-banner {
  background-color: var(--light-gray);
  aspect-ratio: 1 / 0.47;
}

.Question-card .card-content {
  padding: 20px;
}

.Question-card .card-title {
  transition: var(--transition-1);
}

.Question-card .card-title:is(:hover, :focus) {
  color: var(--winter-sky);
}

.Question-card .card-text {
  margin-block: 10px 20px;
  line-height: 1.8;
}

.Question-card .btn-link {
  color: var(--ultramarine);
}
